<template>
  <main class="main">
    <section class="section" id="s5">
      <comp-slider :slider="cc.s5.slider"/>
    </section>
  </main>
</template>

<script>
import compSlider from '@/components/Slider'
export default {
  components: {
    compSlider
  },
  computed: {
    cc () {
      return this.$t('pages.hp')
    }
  },
  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
}
</script>
